import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
//My Components
import Top from "../components/Top"
import ContactForm from "../components/ContactForm"
import Footer from "../components/Footer"
import Seo from "../components/Seo"
// Galeria from "../components/klimatyzacja/Galeria"

// Query for Top image and Contact Form background image
export const query = graphql`
  {
    form: file(
      name: { eq: "klimatyzacja" }
      sourceInstanceName: { eq: "formBackgrounds" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
    top: file(
      name: { eq: "klimatyzacja" }
      sourceInstanceName: { eq: "topImages" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`

const Fotowoltaika = () => {
  const data = useStaticQuery(query)
  const imgForm = getImage(data.form)
  const imgTop = getImage(data.top)
  return (
    <div id="klimatyzacja_galeria" className="page klimatyzacjaPage">
      <Seo title="Fotowoltaika" />
      <Top image={imgTop} />
      <p>Wkrótce...</p>
      <ContactForm backgroundImage={imgForm} nrTel="781 144 999" />
      <Footer nrTel="781 144 999" email="oze@eka.pl" />
    </div>
  )
}

export default Fotowoltaika
